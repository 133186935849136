export const goldMedalTimes = {
  'M1x': '6:32',
  'M2x': '6:00',
  'M4x': '5:31',
  'M2-': '6:11',
  'M4-': '5:40',
  'M4+': '5:53',
  'M8+': '5:18',
  'W1x': '7:10', 
  'W2x': '6:36', 
  'W4x': '6:06', 
  'W2-': '6:50', 
  'W4-': '6:18', 
  'W4+': '6:28', 
  'W8+': '5:52'
}
